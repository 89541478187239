import React from "react";
import HeaderShop from "../header/HeaderShop";

const Shop = () => {
  return (
    <>
      {/* <HeaderShop /> */}
      <div className="shopPage">
        <h1>Products</h1>
        {}
      </div>
    </>
  );
};

export default Shop;
